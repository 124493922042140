import { watchEffect } from "vue";
import useCountdown from "./useCountdown";
import pluralize from "./pluralize";

const countdownState = useCountdown(new Date("2022-11-24T09:00:00"));

export const init = (element) => {
  const counters = element.querySelectorAll("[data-countdown]");
  const texts = element.querySelectorAll("[data-countdown-text]");

  watchEffect(() => {
    counters.forEach((el) => {
      const { countdown } = el.dataset;

      el.innerText = countdownState[countdown].value;
    });

    texts.forEach((el) => {
      const { countdownText } = el.dataset;
      const suffix = "s";

      el.innerText = pluralize(
        countdownText,
        countdownState[countdownText + suffix].value,
        suffix,
        true,
      );
    });
  });
};
