import emailjs from "@emailjs/browser";

emailjs.init("qfC2a1T3nJjEHW67G");

const sendLead = (form) => {
  const formData = new FormData();
  const params = {};

  new FormData(form).forEach((value, key) => (params[key] = value));

  formData.append(
    "q",
    JSON.stringify({ cmd: "addLead", params: { source: "CyLead", ...params } }),
  );

  return fetch("https://tradernet.com/api", {
    method: "POST",
    body: formData,
  });
};

export default function send(form) {
  return Promise.all([
    sendLead(form),
    emailjs.sendForm("service_hzpcgjf", "template_0qvx8ih", form),
  ]);
}
