export default function pluralize(
  string,
  count,
  suffix = "s",
  withoutNumber = false,
) {
  const result = `${string}${count != 1 ? suffix : ""}`;

  if (withoutNumber) return result;

  return `${count} ${result}`;
}
