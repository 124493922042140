import Swiper from "swiper";
import { init } from "./countdown";
import send from "./sendMail";

new Swiper(".speakers-swiper", {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 12,
  breakpoints: {
    768: {
      slidesPerView: 8,
      centeredSlides: false,
      spaceBetween: 0,
    },
  },
});

const bodySelector = document.querySelector("body");
const htmlSelector = document.querySelector("html");
const mobileMenu = document.querySelector("#mobileMenu");
const mobileMenuLink = document.querySelectorAll("#mobileMenu a");
const countdown = document.querySelector(".js-countdown");
const registrationForm = document.querySelector("#registration-form");
const modalSend = document.querySelector("#modalSend");
const modalClose = document.querySelector("#modalClose");
const ringColor = "!ring-red-500";

document.querySelector("#openMenu").addEventListener("click", function () {
  if (
    !bodySelector.classList.contains("fixed") &&
    mobileMenu.classList.contains("hidden")
  ) {
    mobileMenu.classList.remove("hidden");
    bodySelector.classList.add("fixed");
  } else {
    mobileMenu.classList.add("hidden");
    bodySelector.classList.remove("fixed");
  }
});

mobileMenuLink.forEach(function (item) {
  item.addEventListener("click", function () {
    mobileMenu.classList.add("hidden");
    bodySelector.classList.remove("fixed");
  });
});

modalSend.addEventListener("click", function () {
  this.classList.add("hidden");
  htmlSelector.classList.remove("overflow-hidden");
});

modalClose.addEventListener("click", function () {
  modalSend.classList.add("hidden");
  htmlSelector.classList.remove("overflow-hidden");
});

registrationForm.addEventListener("submit", (e) => {
  e.preventDefault();

  var emailRegexp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    ),
    inputsForm = document.querySelectorAll(".required-input"),
    isValid = true;

  inputsForm.forEach(function (item) {
    item.classList.remove(ringColor);

    if (
      item.getAttribute("name") === "email" &&
      !emailRegexp.test(item.value)
    ) {
      item.classList.add(ringColor);
      isValid = false;
    } else if (!item.value) {
      item.classList.add(ringColor);
      isValid = false;
    }
  });

  if (!isValid) {
    return false;
  }

  const btn = e.target.querySelector("button");

  btn.innerText = "Sending...";
  btn.disabled = true;

  send(e.target).finally(() => {
    btn.innerText = "send request";
    btn.disabled = false;
    modalSend.classList.remove("hidden");
    htmlSelector.classList.add("overflow-hidden");
    e.target.reset();
  });
});

countdown && init(countdown);
