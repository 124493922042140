import { ref } from "vue";

const DAY = 1000 * 60 * 60 * 24;
const HOUR = 1000 * 60 * 60;
const MINUTE = 1000 * 60;
const MILLISECONDS = 1000;
const INTERVAL_DURATION = 1000;

export function padZerosInToNumber(number, maxLength = 2) {
  return String(number).padStart(maxLength, "0");
}

export default function useCountdown(date) {
  const timerTime = date.getTime();

  const days = ref("");
  const hours = ref("");
  const minutes = ref("");
  const seconds = ref("");

  function tick() {
    const now = Date.now();
    const distance = timerTime - now;

    if (distance <= 0) {
      clearInterval(interval);

      return;
    }

    days.value = padZerosInToNumber(Math.floor(distance / DAY));
    hours.value = padZerosInToNumber(Math.floor((distance % DAY) / HOUR));
    minutes.value = padZerosInToNumber(Math.floor((distance % HOUR) / MINUTE));

    seconds.value = padZerosInToNumber(
      Math.floor((distance % MINUTE) / MILLISECONDS),
    );
  }

  const interval = setInterval(tick, INTERVAL_DURATION);

  tick();

  return { days, hours, minutes, seconds };
}
